import React from 'react'
import { CalcProvider } from '../../../context/calcContext'
import { BrewProvider } from '../../../context/brewContext'
import { TimerProvider } from '../../../context/timerContext'
import { SettingsProvider } from '../../../context/settingsContext'
import { LogProvider } from '../../../context/logContext'
import ClientOnly from '../ClientOnly/ClientOnly'
import SEO from '../../SEO/SEO'

const Layout = ({ children }: { children: React.ReactNode }) => (
  <SEO>
    <ClientOnly>
      <div className="h-screen overflow-x-clip overflow-y-scroll">
        <SettingsProvider>
          <TimerProvider>
            <CalcProvider>
              <LogProvider>
                <BrewProvider>
                  <div>
                    {children}
                  </div>
                </BrewProvider>
              </LogProvider>
            </CalcProvider>
          </TimerProvider>
        </SettingsProvider>
      </div>
    </ClientOnly>
  </SEO>
)
export default Layout
