import { CalcEntry } from '../../context/calcContext.types'

const calculate = (name: string, value: number | string, calcData: CalcEntry) => {
  let values = {}
  if (name === 'water') {
    values = {
      coffee:
                Math.round(
                  (value as number / calcData.ratio + Number.EPSILON) * 100,
                ) / 100,
    }
    return values
  }
  if (name === 'coffee') {
    values = {
      water: Math.round(value as number * calcData.ratio),
    }
    return values
  }
  if (name === 'ratio') {
    values = {
      water: Math.round(value as number * calcData.coffee),
    }
    return values
  }
  return values
}

export default calculate
