import { LogEntryType } from '../context/logContext.types'
import calculate from '../components/Calculate/helper'

type LogAction =
    | { type: 'collapse_all' }
    | { type: 'copy_entry', dateStamp: number, sortType: string }
    | { type: 'delete_all' }
    | { type: 'delete_entry', dateStamp: number }
    | { type: 'delete_key_from_entry', dateStamp: number, entry: keyof LogEntryType }
    | { type: 'edit_entry', dateStamp: number, event: { target?: { name: string; value: number | string; checked?: boolean }, details?: LogEntryType['details'] }}
    | { type: 'expand_all' }
    | { type: 'search_log' }
    | { type: 'sort_log', sortMethod: string }
    | { type: 'update_log', update: LogEntryType }

const sortLog = (toSort: LogEntryType[], sortMethod: string) => {
  const copy = [...toSort]
  const logHasNames = toSort.some((item) => Object.hasOwnProperty.call(item, 'name') && item.name !== '')
  const withNamesSorted = copy.filter((item) => Object.hasOwnProperty.call(item, 'name') && item.name !== '').sort((a, b) => a.name!.toLowerCase().localeCompare(b.name!.toLowerCase(), undefined, { ignorePunctuation: true }))
  const withoutNamesDescending = copy.filter((item) => !Object.hasOwnProperty.call(item, 'name') || item.name === '')

  if (sortMethod === 'alphaD' && logHasNames) {
    return [...withoutNamesDescending, ...withNamesSorted]
  }
  if (sortMethod === 'alphaA' && logHasNames) {
    return [...withoutNamesDescending, ...withNamesSorted.reverse()]
  }
  if (sortMethod === 'ascending') copy.sort((a, b) => new Date(a.date!).getTime() - new Date(b.date!).getTime()).reverse()
  if (sortMethod === 'descending') copy.sort((a, b) => new Date(a.date!).getTime() - new Date(b.date!).getTime())
  return copy
}

const logReducer = (log: LogEntryType[], action: LogAction): LogEntryType[] => {
  switch (action.type) {
    case 'collapse_all': {
      const copy = [...log]
      return copy.map((entry) => {
        entry.details = {
          beanDetails: false,
          filterDetails: false,
          flavourDetails: false,
          grindDetails: false,
          stepDetails: false,
          waterDetails: false,
        }
        return entry
      })
    }

    case 'copy_entry': {
      const copy = JSON.parse(JSON.stringify(log.filter((entry) => entry.date === action.dateStamp)))
      Object.assign(copy[0], {
        date: Date.now(),
      })
      return sortLog([...log, copy[0]], action.sortType)
    }

    case 'delete_all': {
      return []
    }

    case 'delete_entry': {
      return log.filter((entry) => entry.date !== action.dateStamp)
    }

    case 'delete_key_from_entry': {
      const index = log.findIndex(
        (item) => item.date === action.dateStamp,
      )
      delete log[index][action.entry]
      return log
    }

    case 'edit_entry': {
      const index = log.findIndex(
        (entry) => entry.date === action.dateStamp,
      )
      if (action.event.details) {
        return [
          ...log.slice(0, index),
          { ...log[index], details: action.event.details },
          ...log.slice(index + 1),
        ]
      }
      if (action.event.target) {
        return [
          ...log.slice(0, index),
          {
            ...log[index],
            [action.event.target.name]: action.event.target.value ?? action.event.target.checked,
            ...calculate(
              action.event.target.name,
              action.event.target.value,
              log[index],
            ),
          },
          ...log.slice(index + 1),
        ]
      }
      return log
    }

    case 'expand_all': {
      return log.map((entry) => {
        entry.details = {
          beanDetails: true,
          filterDetails: true,
          flavourDetails: false,
          grindDetails: true,
          stepDetails: true,
          waterDetails: true,
        }
        return entry
      })
    }

    case 'sort_log': {
      return sortLog(log, action.sortMethod)
    }

    case 'update_log': {
      return [...log, action.update]
    }

    default: {
      return log
    }
  }
}

export default logReducer
