import React, {
  createContext,
  useMemo,
  useReducer,
} from 'react'
import { CalcContextProps, CalcEntry, CalcProviderProps } from './calcContext.types'
import calcReducer from '../reducers/calcReducer'

export const CalcContext = createContext({} as CalcContextProps)
CalcContext.displayName = 'CalcContext'

const createInitialCalcState = () => ({
  water: 250,
  ratio: 16.5,
  coffee: 15,
})

export const CalcProvider = ({ children }: CalcProviderProps) => {
  const [calcData, dispatch] = useReducer(calcReducer, null, createInitialCalcState)

  const calculate = (name: keyof CalcEntry, value: string | number) => {
    dispatch({ type: 'calculateValues', name, value: value as number })
  }

  const round = (roundCoffee: boolean) => {
    dispatch({ type: 'round', roundCoffee })
  }

  const value = useMemo(
    () => ({
      calcData,
      calculate,
      round,
    }),
    [calcData],
  )

  return <CalcContext.Provider value={value}>{children}</CalcContext.Provider>
}
