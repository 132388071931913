import React from 'react'

export interface BrewProviderProps {
    children: React.ReactElement
}

export type TimePointItem = {
    seconds: number,
    water: number,
    temperature: number,
    pour: 'centre' | 'round' | 'off-centre'
}

export type BrewItem = {
    compoundSeconds: number,
    compoundWater: number,
    gPerSecond: number,
    pour: string,
    seconds: number,
    secondsOffset: number,
    water: number,
    temperature: number,
    timeCompleted: boolean,
    timePouring: boolean,
}

export type BrewTotals = {
    bloomMultiplier: number,
    bloomSeconds: number,
    bloomSteps: | 0 | 1 | 2 | 3,
    bloomWater: number,
    coffee: number,
    seconds: number,
    steps: 0 | 1 | 2 | 3 | 4 | 5 | 6,
    water: number,
}

export type Brew = {
    bloom: BrewItem[]
    steps: BrewItem[]
    totals: BrewTotals
}

export interface BrewContextProps {
    brew: Brew,
    addTimeOffsetToStep: (stepIndex: number, timeAmount: number, bloom?: boolean) => void,
    resetTimeOffset: () => void,
    setTotals: (key: keyof BrewTotals, value: number) => void,
}

export const createInitialBrewState = (totals: BrewTotals): Brew => ({
  bloom: Array.from({ length: totals.bloomSteps }, (_) => ({
    compoundSeconds: 0,
    compoundWater: Math.round(totals.coffee * totals.bloomMultiplier),
    gPerSecond: 5,
    pour: 'round',
    seconds: totals.bloomSeconds,
    secondsOffset: 0,
    temperature: 90,
    timeCompleted: false,
    timePouring: false,
    water: Math.round(totals.coffee * totals.bloomMultiplier),
  })),
  steps: Array.from({ length: totals.steps }, (_, index) => ({
    compoundSeconds: Math.round(((totals.seconds - totals.bloomSeconds) / totals.steps) * (index) + totals.bloomSeconds),
    compoundWater: Math.round(((totals.water - Math.round(totals.coffee * totals.bloomMultiplier)) / totals.steps) * (index + 1) + Math.round(totals.coffee * totals.bloomMultiplier)),
    gPerSecond: 5,
    pour: 'round',
    seconds: Math.round((totals.seconds - totals.bloomSeconds) / totals.steps),
    secondsOffset: 0,
    temperature: 90,
    timeCompleted: false,
    timePouring: false,
    water: Math.round((totals.water - Math.round(totals.coffee * totals.bloomMultiplier)) / totals.steps),
  })),
  totals: {
    bloomMultiplier: totals.bloomMultiplier,
    bloomSeconds: totals.bloomSeconds,
    bloomSteps: totals.bloomSteps,
    bloomWater: Math.round(totals.coffee * totals.bloomMultiplier),
    coffee: totals.coffee,
    seconds: totals.seconds,
    steps: totals.steps,
    water: totals.water,
  },
})
