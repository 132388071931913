export type Time = {
    brewTimer: {
        time: number,
        reset: boolean,
        running: boolean,
        paused: boolean,
        referenceTime: number,
    }
    brewBgTimer: {
        time: number,
        referenceTime: number,
    }

}

type TimerAction =
    | { type: 'calculateTimer' }
    | { type: 'calculatePause' }
    | { type: 'reset' }
    | { type: 'setTime', seconds: number }
    | { type: 'start' }
    | { type: 'stop' }
    | { type: 'preventInfinite' }

const timerReducer = (time: Time, action: TimerAction): Time => {
  switch (action.type) {
    case 'calculateTimer': {
      const now = Date.now()
      const interval = now - time.brewTimer.referenceTime
      return {
        ...time,
        brewTimer: {
          ...time.brewTimer,
          time: time.brewTimer.time + interval,
          referenceTime: now,
        },
      }
    }
    case 'calculatePause': {
      const now = Date.now()
      const interval = now - time.brewBgTimer.referenceTime
      return {
        ...time,
        brewBgTimer: {
          time: time.brewBgTimer.time + interval,
          referenceTime: now,
        },
      }
    }
    case 'preventInfinite': {
      return {
        brewTimer: {
          paused: false,
          reset: false,
          running: false,
          time: 0,
          referenceTime: 0,
        },
        brewBgTimer: {
          time: 0,
          referenceTime: 0,
        },
      }
    }
    case 'reset': {
      return {
        brewTimer: {
          paused: false,
          reset: true,
          time: 0,
          referenceTime: 0,
          running: false,
        },
        brewBgTimer: {
          time: 0,
          referenceTime: 0,
        },
      }
    }
    case 'setTime': {
      return {
        ...time,
        brewTimer: {
          ...time.brewTimer,
          time: action.seconds * 1000,
        },
      }
    }
    case 'start': {
      return {
        brewTimer: {
          ...time.brewTimer,
          paused: time.brewTimer.running,
          reset: false,
          running: !time.brewTimer.running,
          referenceTime: !time.brewTimer.running ? Date.now() : time.brewTimer.referenceTime,
        },
        brewBgTimer: {
          ...time.brewBgTimer,
          referenceTime: !time.brewTimer.paused ? Date.now() : time.brewBgTimer.referenceTime,
        },
      }
    }
    case 'stop': {
      return {
        ...time,
        brewTimer: {
          ...time.brewTimer,
          running: false,
          paused: false,
        },
      }
    }
    default: {
      return {
        ...time,
      }
    }
  }
}

export default timerReducer
