import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState,
} from 'react'
import { LogContextProps, LogEntryType, LogProviderProps } from './logContext.types'
import logReducer from '../reducers/logReducer'
import { SettingsContext } from './settingsContext'

export const LogContext = createContext({} as LogContextProps)
LogContext.displayName = 'LogContext'

const createInitialState = (): LogEntryType[] => {
  if (localStorage.getItem('log') !== null) {
    return JSON.parse(localStorage.getItem('log') as string)
  }
  return []
}

export const LogProvider = ({ children }: LogProviderProps) => {
  const [saveLogInBrowser, setSaveLogInBrowser] = useState(() => localStorage.getItem('log') !== null)
  const [filteredLog, setFilteredLog] = useState([] as LogEntryType[])
  const [log, dispatch] = useReducer(logReducer, null, createInitialState)
  const [logPresetList, setLogPresetList] = useState({
    water: ['Aquacode', 'TWW Light', 'Coffee Chronicler', 'Coffee Ad Astra'],
  })
  const { settings, changeSingleSetting } = useContext(SettingsContext)

  const editLogEntry = useCallback((type: string, dateStamp: number, event?: { target?: { name: string; value: number | string; checked?: boolean }, details?: LogEntryType['details'] }, entry?: keyof LogEntryType) => {
    const sortType = settings.singleVars?.sortLog ? settings.singleVars.sortLog : 'descending'
    if (type === 'copy') dispatch({ type: 'copy_entry', sortType, dateStamp })
    if (type === 'delete') dispatch({ type: 'delete_entry', dateStamp })
    if (type === 'delete-key' && entry) dispatch({ type: 'delete_key_from_entry', entry, dateStamp })
    if (type === 'edit' && event) dispatch({ type: 'edit_entry', dateStamp, event })
  }, [settings.singleVars.sortLog])

  const editWholeLog = useCallback((type: string, sortTypeInt?: string, update?: LogEntryType) => {
    if (type === 'delete') dispatch({ type: 'delete_all' })
    if (type === 'collapse') dispatch({ type: 'collapse_all' })
    if (type === 'expand') dispatch({ type: 'expand_all' })
    if (type === 'sort' && sortTypeInt) {
      changeSingleSetting('sortLog', sortTypeInt)
      dispatch({ type: 'sort_log', sortMethod: sortTypeInt })
    }
    if (type === 'update' && update) dispatch({ type: 'update_log', update })
  }, [changeSingleSetting])

  useEffect(() => {
    if (saveLogInBrowser) {
      localStorage.setItem('log', JSON.stringify(log))
    }
    if (!saveLogInBrowser) {
      localStorage.removeItem('log')
    }
  }, [log, saveLogInBrowser])

  const value = useMemo(
    () => ({
      editLogEntry,
      editWholeLog,
      filteredLog,
      log,
      logPresetList,
      saveLogInBrowser,
      setFilteredLog,
      setLogPresetList,
      setSaveLogInBrowser,
    }),
    [editLogEntry, editWholeLog, filteredLog, log, logPresetList, saveLogInBrowser],
  )

  return <LogContext.Provider value={value}>{children}</LogContext.Provider>
}
