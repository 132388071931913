import React, { useEffect, useState } from 'react'

interface ClientOnlyProps {
    children: React.ReactNode
}

const ClientOnly = ({ children }: ClientOnlyProps) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return <div />
  }
  return (
    <div>
      {children}
    </div>
  )
}

export default ClientOnly
