import React, {
  createContext,
  useMemo,
  useContext,
  useReducer,
  useEffect, useCallback,
} from 'react'
import {
  BrewContextProps,
  BrewProviderProps,
  BrewTotals,
  createInitialBrewState,
} from './brewContext.types'
import { CalcContext } from './calcContext'
import brewReducer from '../reducers/brewReducer'
import { SettingsContext } from './settingsContext'
import { TimerContext } from './timerContext'

export const BrewContext = createContext({} as BrewContextProps)
BrewContext.displayName = 'BrewContext'

export const BrewProvider = ({ children }: BrewProviderProps) => {
  const { calcData } = useContext(CalcContext)
  const { settings } = useContext(SettingsContext)
  const { time } = useContext(TimerContext)
  const [brew, dispatch] = useReducer(
    brewReducer,
    {
      bloomMultiplier: 3,
      bloomSeconds: 45,
      bloomSteps: 1,
      bloomWater: Math.round(calcData.coffee * 3),
      coffee: calcData.coffee,
      seconds: 240,
      steps: 2,
      water: calcData.water,
    },
    createInitialBrewState,
  )

  useEffect(() => {
    if (settings.presets.brew.defaults) {
      const defaultTotals = settings.presets.brew.defaults
      dispatch({
        type: 'set_all_totals',
        totals: {
          bloomMultiplier: defaultTotals.bloomMultiplier,
          bloomSeconds: defaultTotals.bloomSeconds,
          bloomSteps: defaultTotals.bloomSteps,
          bloomWater: Math.round(calcData.coffee * 3),
          coffee: calcData.coffee,
          seconds: defaultTotals.seconds,
          steps: defaultTotals.steps,
          water: calcData.water,
        },
      })
      dispatch({ type: 'calculate_steps', calc: { coffee: calcData.coffee, ratio: calcData.ratio, water: calcData.water } })
    }
  }, [calcData.coffee, calcData.ratio, calcData.water, settings.presets.brew.defaults])

  const addTimeOffsetToStep = (
    stepIndex: number,
    timeAmount: number,
    bloom?: boolean,
  ) => {
    dispatch({
      type: 'add_time_offset_to_step',
      bloom,
      stepIndex,
      timeAmount,
    })
  }

  const resetTimeOffset = () => dispatch({ type: 'reset_time_offset' })

  const setTotals = useCallback((key: keyof BrewTotals, value: number) => {
    if (key === 'steps' && value <= 6) {
      dispatch({ type: 'set_totals', key, value })
      dispatch({ type: 'calculate_steps', calc: { coffee: brew.totals.coffee, ratio: calcData.ratio, water: brew.totals.water } })
    }
    if (key === 'steps' && value > 6) {
      return Error('Step limit is 6 steps')
    }
    if (key === 'water') {
      dispatch({ type: 'set_totals', key, value })
      dispatch({ type: 'calculate_steps', calc: { coffee: brew.totals.coffee, ratio: calcData.ratio, water: value } })
    }
    if (key === 'bloomSteps' && value <= 2) {
      dispatch({ type: 'set_totals', key, value })
      dispatch({ type: 'calculate_steps', calc: { coffee: brew.totals.coffee, ratio: calcData.ratio, water: brew.totals.water } })
    }
    if (key === 'bloomSteps' && value > 2) {
      return Error('Bloom limit is 2 steps')
    }
    if (key === 'bloomSeconds' || key === 'seconds') {
      dispatch({ type: 'set_totals', key, value })
      dispatch({ type: 'calculate_steps', calc: { coffee: brew.totals.coffee, ratio: calcData.ratio, water: brew.totals.water } })
    }
    return dispatch({ type: 'set_totals', key, value })
  }, [brew.totals.coffee, brew.totals.water, calcData.ratio])

  useEffect(() => {
    dispatch({ type: 'calculate_steps', calc: calcData })
  }, [calcData])

  useEffect(() => {
    if (time.brewTimer.reset) dispatch({ type: 'reset_time_offset' })
  }, [time.brewTimer.reset])

  const value = useMemo(
    () => ({
      brew,
      addTimeOffsetToStep,
      resetTimeOffset,
      setTotals,
    }),
    [brew, setTotals],
  )

  return <BrewContext.Provider value={value}>{children}</BrewContext.Provider>
}
