import React from 'react'
import useSiteMetadata from '../../hooks/useSiteMetadata'

interface SEOProps extends React.HTMLProps<HTMLDivElement> {
    title?: string,
    description?: string,
    image?: string,
    pathname?: string,
    children?: React.ReactNode
}

const SEO = ({
  title, description, pathname, image, children,
}: SEOProps) => {
  const {
    title: defaultTitle, description: defaultDescription, siteUrl,
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ''}`,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta content="cupendium // pour over coffee tools & tasting journal" name="title" />
      <meta content={seo.description} name="description" />

      <meta content="website" property="og:type" />
      <meta content="https://www.cupendium.com/" property="og:url" />
      <meta content="cupendium // pour over coffee tools & tasting journal" property="og:title" />
      <meta content="Tasting Log, Digital & Printable Tools for Brew-Over Coffee" property="og:description" />
      <meta content={seo.image} property="og:image" />

      <meta content="summary_large_image" property="twitter:card" />
      <meta content="https://www.cupendium.com/" property="twitter:url" />
      <meta content="cupendium // pour over coffee tools & tasting journal" property="twitter:title" />
      <meta content="Tasting Log, Digital & Printable Tools for Brew-Over Coffee" property="twitter:description" />
      <meta content={seo.image} property="twitter:image" />

      <meta content="pour-over, pourover, pour over coffee, coffee, coffee calculator" name="keywords" />
      <meta content="noindex, nofollow" name="robots" />
      <meta content="text/html; charset=utf-8" httpEquiv="Content-Type" />
      <meta content="English" name="language" />
      <meta content="MAT-AT | Wisdom & Madness Design Co." name="author" />
      <meta
        content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi"
        name="viewport"
      />
      {children}
    </>
  )
}

export default SEO
