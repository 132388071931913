import { CalcEntry } from '../context/calcContext.types'
import calculate from '../components/Calculate/helper'

type CalcAction =
    | { type: 'calculateValues', name: keyof CalcEntry, value: number }
    | { type: 'round', roundCoffee: boolean }

const calcReducer = (calcData: CalcEntry, action: CalcAction): CalcEntry => {
  switch (action.type) {
    case 'calculateValues': {
      if ((action.value) > 0) {
        return {
          ...calcData,
          [action.name]: action.value,
          ...calculate(action.name, action.value, calcData),
        }
      }
      return { ...calcData }
    }

    case 'round': {
      if (action.roundCoffee) {
        return {
          ...calcData,
          coffee: Math.round(calcData.coffee),
        }
      } return {
        ...calcData,
        water: Math.round(calcData.water),
      }
    }

    default: {
      return { ...calcData }
    }
  }
}

export default calcReducer
